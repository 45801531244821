import { Reducer } from 'react'
import routes from '../Router/RootRoutes'
import { IAction } from './Types'

export interface GlobalState {
  routes: any
  drawerOpen: boolean
  bottomNavPosition?: number
  hotelOrGuest?: string
  checkInRequired?: boolean
  bookingDetails: any
  Errors: any
  OnLoading: boolean
  OnSnackBar?: SnackBarType
  HeaderSection?: any
}
export const GlobalInitialState: GlobalState = {
  routes: routes,
  drawerOpen: true,
  bottomNavPosition: 0,
  hotelOrGuest: '',
  checkInRequired: true,
  bookingDetails: null,
  Errors: {
    Error: false,
    Message: '',
  },
  OnLoading: false,
  OnSnackBar: {
    Open: false,
    Message: '',
    onClick: () => {},
  },
  HeaderSection: {
    header: {
      headerMode: 'primary',
    },
  },
}

type SnackBarType = {
  Open?: boolean
  Message?: string
  onClick?: any
}
export interface RootStateProps {
  OnLoading: boolean
  OnSnackBar?: SnackBarType
  HeaderSection?: any
}

export const  RootReducer: Reducer<RootStateProps, IAction> = (
  state,
  action
) => {
  switch (action.type) {
    
    case 'routes':
      return {
        ...state,
        routes: action.payload,
      }
    case 'drawerOpen':
      return { ...state, drawerOpen: action.payload }
    case 'bottomNavPosition':
      return { ...state, bottomNavPosition: action.payload }
    case 'hotelOrGuest':
      return { ...state, hotelOrGuest: action.payload }
    case 'checkInRequired':
      return { ...state, checkInRequired: action.payload }
    case 'bookingDetails':
      return { ...state, bookingDetails: action.payload }
    case 'Errors':
      return { ...state, Errors: action.payload }
      case 'CloseSnackBar':
      return {
        ...state,
        OnSnackBar: {
          Open: false,
          Message: '',
          onClick: () => {},
        },
      }
    case 'Reset':
      return GlobalInitialState
    default:
      return { ...state, [action.type]: action.payload }
  }
}
