import React, { lazy } from 'react'
import { AttachmentsListing } from './AttachmentsModule/AttachmentsListing'

const Booking = lazy(() =>
  import('./Booking').then(module => ({
    default: module.Booking,
  }))
)
const EditBooking = lazy(() =>
  import('./EditBooking').then(module => ({
    default: module.EditBooking,
  }))
)
const EditRoomType = lazy(() =>
  import('./EditRoomType').then(module => ({
    default: module.EditRoomType,
  }))
)
const GuestList = lazy(() =>
  import('./GuestList').then(module => ({
    default: module.GuestList,
  }))
)
const AdvancePaymentDoc = lazy(() =>
  import('./AdvancePaymentDoc').then(module => ({
    default: module.AdvancePaymentDoc,
  }))
)
const AdvancePaymentDocument = lazy(() =>
  import('./AdvancePaymentDocument').then(module => ({
    default: module.AdvancePaymentDocument,
  }))
)
const ContractDetails = lazy(() =>
  import('./ContractDetails').then(module => ({
    default: module.ContractDetails,
  }))
)
const AdvancePaymentListing = lazy(() =>
  import('./AdvancePaymentListing').then(module => ({
    default: module.AdvancePaymentListing,
  }))
)

const SubMenu = lazy(() =>
  import('./SubMenu').then(module => ({
    default: module.SubMenu,
  }))
)
const AddBooking = lazy(() =>
  import('./AddBooking').then(module => ({
    default: module.AddBooking,
  }))
)
const BookingAuditLog = lazy(() =>
  import('./BookingAuditLog').then(module => ({
    default: module.BookingAuditLog,
  }))
)
const Guest = lazy(() =>
  import('./Guest').then(module => ({
    default: module.Guest,
  }))
)
const GuestForm = lazy(() =>
  import('./GuestForm').then(module => ({
    default: module.GuestForm,
  }))
)

export const addBookingPath = '/add'
export const subMenuPath = '/submenu'
export const attachmentsPath = '/booking/attachments'
export const editBookingPath = '/submenu/edit'
export const BookingSubMenuPath = '/booking/bookingdetails'
export const roomingListPath = '/submenu/roominglist'
export const roomingGuestListPath = '/submenu/roominglist/guest'
export const roomingGuestAddPath = '/submenu/roominglist/guest/add'
export const roomingGuestEditPath = '/submenu/roominglist/guest/edit'
export const AdvpaymentListPath = '/submenu/advancepayment'
export const advancePaymentDocPath = '/submenu/advancepayment/folio'
export const bookingAuditLogPath = '/booking/submenu/auditlog'
export const PAYMENT_REDIRECT = '/redirect/status'
const bookingRoutes = [
  {
    props: { exact: true, path: '/booking' },
    component: <Booking />,
  },

  {
    props: { exact: true, path: editBookingPath },
    component: <EditBooking />,
  },
  {
    props: { exact: true, path: '/booking/editroomtype' },
    component: <EditRoomType />,
  },
  {
    props: { exact: true, path: roomingListPath },
    component: <GuestList />,
  },
  {
    props: { exact: true, path: roomingGuestListPath },
    component: <Guest />,
  },
  {
    props: { exact: true, path: roomingGuestAddPath },
    component: <GuestForm mode={'Add'} />,
  },
  
  {
    props: { exact: true, path: roomingGuestEditPath },
    component: <GuestForm mode={'Edit'} />,
  },
  {
    props: { exact: true, path: AdvpaymentListPath },
    component: <AdvancePaymentListing />,
  },
  {
    props: { exact: true, path: advancePaymentDocPath },
    component: <AdvancePaymentDoc mode={'Booking'} type={'Receipt'} />,
  },

  {
    props: { exact: true, path: '/booking/advancepayment/redirect/:status' },
    component: <AdvancePaymentDocument />,
  },
  {
    props: { exact: true, path: '/booking/contract' },
    component: <ContractDetails />,
  },
  {
    props: { exact: true, path: subMenuPath },
    component: <SubMenu />,
  },
  {
    props: { exact: true, path: addBookingPath },
    component: <AddBooking />,
  },
  {
    props: { exact: true, path: editBookingPath },
    component: <EditBooking />,
  },
  {
    props: { exact: true, path: bookingAuditLogPath },
    component: <BookingAuditLog />,
  },
  {
    props: { exact: true, path: attachmentsPath },
    component: <AttachmentsListing type={'Booking'}/>,
  },
]

export default bookingRoutes
