// import { getAccessToken } from 'helpers/AccessToken'
import { useIsTravelAgentContractExpiredLazyQuery, useIsTravelAgentContractExpiredQuery, useLoggedInDebtorProfileQuery } from 'generated/graphql'
import { useSnackBar } from 'helpers/hooks/useSnackBar'
import React, { useContext } from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'
import AppContext from 'containers/App/Store/AppContext'
export const PrivateRoute = ({ children, ...rest }) => {
  const { globalState, dispatch: globalDispatch } = useContext(
    AppContext as any
  )
  // const AccessToken = getAccessToken()
  const token = JSON.parse(localStorage.getItem('DebtorInfo'))?.accessToken
  const { snackFunc, snackBarMessage, openSnackBar } = useSnackBar()
  const history = useHistory()
 //add here blocked url
 const blockedUrls = [
  '/add', 
  '/booking'
];
const debtor:any = JSON.parse(localStorage.getItem('DebtorInfo'))

  const [IsTravelAgentContractExpiredquery,
    { data: { IsTravelAgentContractExpired } = { IsTravelAgentContractExpired: null } },] = useIsTravelAgentContractExpiredLazyQuery({
    fetchPolicy: 'no-cache',

    onCompleted(data) {
      const currentUrl = window.location.pathname;
      const isBlockedUrl = blockedUrls.includes(currentUrl);
      if(isBlockedUrl && data.IsTravelAgentContractExpired){

        globalDispatch({
          type: 'OnSnackBar',
          payload: {
            ...globalState.OnSnackBar,
            Open: true,
            Message:
            data.IsTravelAgentContractExpired === true ? 
            'The Contract has expired, Please contact hotel staff to renew your contract'
            : '',

            onClick: () =>
              globalDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
       
        setTimeout(() => {
          history.push('/debtorprofile')
        }, 3000)
      }
     
     }
  })

   

  const {
    data: { loggedInDebtorProfile } = { loggedInDebtorProfile: null },
  } = useLoggedInDebtorProfileQuery({
    fetchPolicy: 'network-only',
    onError: error => {
      // eslint-disable-next-line array-callback-return
      error.graphQLErrors.map(({ message }) => {
        snackFunc(
          message === 'Not Authenticated'
            ? 'Login session expired, Please login again.'
            : message,
          false
        )

        if (message === 'Not Authenticated' || message === 'Session expired, please login again.') {
          setTimeout(() => {
            history.push('login')
            localStorage.clear()
          }, 2000)
        }
      })
    },
    onCompleted(data) {
      IsTravelAgentContractExpiredquery({
        variables: {
          DebtorID: debtor?.Debtor[0]?.ID
        }
      })
    },
  })


  



  return (
    <Route
      {...rest}
      render={({ location }) =>
        token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}
