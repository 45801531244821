import { format, parseISO } from 'date-fns'
import moment from 'moment'

export const formatDate = (date: string) => {
  const result = parseISO(date)
  return date ? format(result, 'dd MMM yyyy') : 'NA'
}
export const formatTime = (date: string) => {
  const result = parseISO(date)
  return date ? format(result, 'p') : 'NA'
}

export const dateTimeFormat = (date: string, pattern: string) => {
  const result = parseISO(date)
  return date ? format(result, pattern) : 'NA'
}

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
export const getBuildDate = epoch => {
  const buildDate = moment(epoch).format('DD-MM-YYYY HH:MM')
  return buildDate
}
