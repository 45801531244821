import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  useInvoiceListingsLazyQuery,
  useOfficialReceiptListingsLazyQuery,
  useDebitNoteListingsLazyQuery,
  useCreditNoteListingsLazyQuery,
  useRefundListingsLazyQuery,
  ArTransactionStatus,
} from 'generated/graphql'
import React, {
  useEffect,
  Reducer,
  useReducer,
  useContext,
  useState,
} from 'react'
import { useHistory, useLocation } from 'react-router'
import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  List,
} from '@material-ui/core'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { formatDate } from 'containers/helper/formatDate'
import { amtStr } from 'containers/helper/numFormatter'
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'

import { KeyboardArrowRight, MonetizationOn } from '@material-ui/icons'
import { useMenuOption } from 'containers/helper/hooks/useMenuOption'
import TextSeparator from '@ifca-root/react-component/src/components/Typography/TextSeparator'
import { IAction } from 'containers/helper/models'
import { useSnackBar } from 'containers/helper/hooks/useSnackBar'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import AppContext from 'containers/App/Store/AppContext'
import { SearchActionHeader } from 'components/Header/SearchActionHeader'

interface DebtorListProps {
  Dialog?: boolean
  DialogMode?: string
  AllocationDialog?: boolean
  ReasonCode?: string
  Reason?: string
  AllocatedArr?: any
}
export const LedgerType = {
  Invoices: 'Invoices',
  DebitNotes: 'Debit Notes',
  CreditNotes: 'Credit Notes',
  OfficialReceipt: 'Official Receipt',
  Refund: 'Refund',
}
export const LedgerListing = props => {
  let history = useHistory()
  let location = useLocation()

  const localState = location?.state as any
  const { ledgerType } = props // Invoices, Debit Notes and etc
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  // listing Query
  const [filterSearch, setFilterSearch] = useState('')

  //   const initialState: DebtorListProps = {
  //     Dialog: false,
  //     AllocationDialog: false,
  //     DialogMode: '',
  //     //Cancel
  //     ReasonCode: '',
  //     Reason: '',
  //     AllocatedArr: [],
  //   }
  //   const reducer: Reducer<DebtorListProps, IAction> = (state, action) => {
  //     switch (action.type) {
  //       case 'cancelDialog':
  //         return { ...state, Dialog: true, DialogMode: 'Cancel' }
  //       case 'allocationDialog':
  //         return { ...state, AllocationDialog: true, DialogMode: 'Allocation' }
  //       case 'allocationDialogClose':
  //         return { ...state, AllocationDialog: false, DialogMode: '' }
  //       case 'dialogClose':
  //         return { ...state, Dialog: false, DialogMode: '' }
  //       case 'reset':
  //         return initialState
  //       default:
  //         return { ...state, [action.type]: action.payload }
  //     }
  //   }
  //   const [state, dispatch] = useReducer(reducer, initialState)
  //   const { dispatch: GlobalDispatch } = useContext(AppContext as any)


  let DebtorID = JSON.parse(localStorage.getItem('DebtorInfo'))?.Debtor[0]?.ID
  const [
    loadInvoiceListing,
    {
      called: invoiceListingCalled,
      loading: invoiceListingLoading,
      error: invoiceError,
      data: { InvoiceListings } = { InvoiceListings: [] },
      refetch: invoiceListingsRefetch,
    },
  ] = useInvoiceListingsLazyQuery({
    fetchPolicy: 'no-cache',

    variables: {
      DebtorID: DebtorID,
      HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
    },
  })

  const [
    loadDebitNoteListing,
    {
      called: debitNoteListingCalled,
      loading: debitNoteListingLoading,
      error: debitNoteError,
      data: { DebitNoteListings } = { DebitNoteListings: [] },
      refetch: debitNoteListingsRefetch,
    },
  ] = useDebitNoteListingsLazyQuery({
    fetchPolicy: 'no-cache',

    variables: {
      DebtorID: DebtorID,
      HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
    },
  })

  const [
    loadCreditNoteListing,
    {
      called: creditNoteListingCalled,
      loading: creditNoteListingLoading,
      error: creditNoteError,

      data: { CreditNoteListings } = { CreditNoteListings: [] },
      refetch: creditNoteListingsRefetch,
    },
  ] = useCreditNoteListingsLazyQuery({
    fetchPolicy: 'no-cache',

    variables: {
      DebtorID: DebtorID,
      HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
    },
  })
  const [
    loadOfficialReceiptListing,
    {
      called: officialReceiptListingCalled,
      loading: officialReceiptListingLoading,
      error: receiptError,
      data: { OfficialReceiptListings } = { OfficialReceiptListings: [] },
      refetch: officialReceiptListingsRefetch,
    },
  ] = useOfficialReceiptListingsLazyQuery({
    fetchPolicy: 'no-cache',

    variables: {
      DebtorID: DebtorID,
      HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
    },
  })

  const [
    loadRefundListing,
    {
      called: refundListingCalled,
      loading: refundListingLoading,
      error: refundError,
      data: { RefundListings } = { RefundListings: [] },
      refetch: refundListingsRefetch,
    },
  ] = useRefundListingsLazyQuery({
    fetchPolicy: 'no-cache',

    variables: {
      DebtorID: DebtorID,
      HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
    },
  })

  useEffect(() => {
    if (!invoiceListingCalled && ledgerType === LedgerType.Invoices) {
      loadInvoiceListing()
    }
    if (!debitNoteListingCalled && ledgerType === LedgerType.DebitNotes) {
      loadDebitNoteListing()
    }
    if (!creditNoteListingCalled && ledgerType === LedgerType.CreditNotes) {
      loadCreditNoteListing()
    }
    if (
      !officialReceiptListingCalled &&
      ledgerType === LedgerType.OfficialReceipt
    ) {
      loadOfficialReceiptListing()
    }
    if (!refundListingCalled && ledgerType === LedgerType.Refund) {
      loadRefundListing()
    }
  }, [])

  const HandleDebtorAccountList = mode =>
    mode === 'Debit Notes'
      ? DebitNoteListings
      : mode === 'Credit Notes'
      ? CreditNoteListings
      : mode === 'Invoices'
      ? InvoiceListings
      : mode === 'Refund'
      ? RefundListings
      : mode === 'Official Receipt'
      ? OfficialReceiptListings
      : []
  const HandleLedgerType = mode =>
    mode === 'Debit Notes'
      ? 'debit'
      : mode === 'Credit Notes'
      ? 'credit'
      : mode === 'Invoices'
      ? 'invoices'
      : mode === 'Refund'
      ? 'refunds'
      : mode === 'Official Receipt'
      ? 'receipts'
      : ''
  //   const HandleStatus = Status =>
  //     Status === ArTransactionStatus.Submit ? (
  //       <span className="desc " style={{ color: '#00bf0f' }}>
  //         Submitted
  //       </span>
  //     ) : Status === ArTransactionStatus.Active ? (
  //       <span className="desc fw-medium">Draft</span>
  //     ) : Status === ArTransactionStatus.Cancelled ? (
  //       <span className="desc ">Cancelled</span>
  //     ) : null
  const HandleRefetch = mode =>
    mode === 'Debit Notes'
      ? debitNoteListingsRefetch()
      : mode === 'Credit Notes'
      ? creditNoteListingsRefetch()
      : mode === 'Invoices'
      ? invoiceListingsRefetch()
      : mode === 'Refund'
      ? refundListingsRefetch()
      : mode === 'Official Receipt'
      ? officialReceiptListingsRefetch()
      : null

  const HandleLoading = () =>
    invoiceListingLoading ||
    debitNoteListingLoading ||
    creditNoteListingLoading ||
    officialReceiptListingLoading ||
    refundListingLoading

  return (
    <>
      {HandleLoading() && <Loading />}
      <MainHeader
        onClick={() => history.push('/account-status')}
        mainBtn="back"
        smTitle={'HotelX Collaborative Platform'}
        title={JSON.parse(localStorage.getItem('Hotel'))?.HotelName}
        routeSegments={[
          { name: 'home' },
          { name: 'home' },
          { name: ledgerType, current: true },
        ]}
      />
      <SearchActionHeader
        search
        title={ledgerType}
        value={(HandleDebtorAccountList(ledgerType) as any).length}
        onChangeAction={e => {
          setFilterSearch(e.target.value)
        }}
        onCloseAction={e => setFilterSearch('')}
      />
      <ContentWrapper search>
        <List className="core-list">
          {HandleDebtorAccountList(ledgerType) === undefined ||
          HandleDebtorAccountList(ledgerType)?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : null}
          {(HandleDebtorAccountList(ledgerType) as any)?.map((el, index) => (
            <ListItem
              key={index}
              selected={el?.Status === ArTransactionStatus.Cancelled}
              style={{
                textDecoration:
                  el?.Status === ArTransactionStatus.Cancelled
                    ? 'line-through'
                    : null,
              }}
            >
              <ListItemText
                primary={
                  <>
                    <span className="desc date-width">
                      {/* {el?.DocumentNo} */}
                      {formatDate(el?.DocumentDate)}
                    </span>
                    {/* desc flex-space color-red */}
                    <span className="xsTitle flex-space">
                      {/* {el?.Description} */}
                      {el?.DocumentNo}
                    </span>
                    <span
                      className="highlight-text desc icon-text"
                      style={{ color: '#2f53e6' }}
                    >
                      <MonetizationOn /> {amtStr(el.Amount)}
                    </span>
                  </>
                }
                secondary={
                  <>
                    {el.Status === ArTransactionStatus.Cancelled ? (
                      <span className="desc flex-space">
                        <span>{`Reason: `}</span>
                        <span>{el?.Reason || 'Cancelled'}</span>
                      </span>
                    ) : (
                      <span className="desc flex-space">
                   {el?.OutstandingAmount === 0
                                ? 'Allocated:'
                                : 'Un-Allocated:'}
                                  {amtStr(el?.OutstandingAmount)}
                        <TextSeparator />
                        <span>{el?.Description}</span>
                      </span>
                    )}

                    {/* {HandleStatus(el.Status)} */}
                  </>
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="more"
                  aria-controls="menu-list"
                  aria-haspopup="true"
                  onClick={e =>
                    history.push(`${location.pathname}/document`, {
                      ViewData: el,
                    })
                  }
                >
                  <KeyboardArrowRight />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </ContentWrapper>

      <Menu
        id="menu-list"
        className="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          onClick={() => {
            history.push(
              `/cityledger/debtor/account/${HandleLedgerType(
                ledgerType
              )}/document`,
              {
                ...localState,
                ViewData: menu.obj,
              }
            )
          }}
        >
          <span className="">Print</span>
        </MenuItem>
        {menu?.obj?.Status === ArTransactionStatus.Active ? (
          <MenuItem
            onClick={() => {
              history.push(
                `/cityledger/debtor/account/${HandleLedgerType(
                  ledgerType
                )}/edit`,
                {
                  ...localState,
                  EditData: menu.obj,
                }
              )
            }}
          >
            <span className="">Edit</span>
          </MenuItem>
        ) : null}

        {menu?.obj?.Status === ArTransactionStatus.Submit ||
        menu?.obj?.Status === ArTransactionStatus.Cancelled ? (
          <>
            <MenuItem
              onClick={() => {
                history.push(
                  `/cityledger/debtor/account/${HandleLedgerType(
                    ledgerType
                  )}/detail`,
                  {
                    ...localState,
                    ViewData: menu.obj,
                  }
                )
              }}
            >
              <span className="">View</span>
            </MenuItem>
          </>
        ) : null}
      </Menu>
    </>
  )
}
